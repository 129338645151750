/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "本文旨在按字母顺序对团队研究过程中涉及的术语进行分类解释，为对该领域感兴趣的读者提供一份全面的术语表。"), "\n", React.createElement(_components.h3, null, "K-means clustering"), "\n", React.createElement(_components.p, null, "K-均值聚类（K-means clustering）是一种简单而受欢迎的无监督学习算法之一。\n它从随机选择的中心点开始，通过重复调整中心点的位置来寻找最合适的位置。当中心点的位置稳定下来后，给定的数据将被分成k个簇，并且每个簇与其他簇之间的距离差异的方差将被最小化。\n对于这样分组的簇，可以进行标注（Labeling）。"), "\n", React.createElement(_components.h3, null, "K-Nearest Neighbor(KNN)"), "\n", React.createElement(_components.p, null, "K-最近邻算法（K-Nearest Neighbor）是一种易于实现的机器学习算法之一。\n通过该模型学习到的数据可以根据特征形成群集（cluster）。当新的数据进入时，我们可以判断它属于现有数据所形成的群集中的哪个群集。\n这种判断的依据可以从“最近邻”一词中找到，即被分类为与输入数据最接近的群集。"), "\n", React.createElement(_components.h3, null, "Knowledge Graph Embeddings"), "\n", React.createElement(_components.p, null, "知识图谱（Knowledge Graph）是谷歌使用的一种知识库，利用从各种来源积累的语义搜索信息来改善搜索结果。\n简而言之，知识图谱将大量的知识、实体等信息组成单个节点，并以图形的形式展示它们之间的关系。知识图谱嵌入（Knowledge Graph Embedding）是表示学习的一种方法，旨在将图的属性映射到向量空间中，同时保持其特性。"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
